class Organisateur {
  constructor(pseudo, email) {
    this.pseudo = pseudo;
    this.nom = "";
    this.prenom = "";
    this.email = email;
    this.numTel = "";
    this.photoURL = "";
    this.adresse = "";
    this.codePostal = "";
    this.ville = "";
    this.societe = "";
    this.siret = "";
    this.ape = ""; //Code association
    this.iban = "";
    this.nomCorresponsant = "";
    this.prenomCorrespondant = "";
    this.mailCorrespondant = "";
    this.numTelCorrespondant = "";
    this.isStripeAccountLinkValidated = false;

    // this.displayName = this.nom + " " + this.prenom ? this.nom + " " + this.prenom : null;
    // this.phoneNumber = this.numTel ? this.numtel : '' ;
    //J'ai rajouté l'iban présent sur le figma
  }

  // Getters
  getIdOrganisateur() {
    return this.idOrganisateur;
  }
  getNom() {
    return this.nom;
  }
  getPrenom() {
    return this.prenom;
  }
  getEmail() {
    return this.email;
  }
  getNumTel() {
    return this.numTel;
  }

  getSociete() {
    return this.societe;
  }
  getSiret() {
    return this.siret;
  }
  getApe() {
    return this.ape;
  }
  getAdresse() {
    return this.adresse;
  }
  getCodePostal() {
    return this.codePostal;
  }
  getVille() {
    return this.ville;
  }
  getNomCorresponsant() {
    return this.nomCorresponsant;
  }
  getPrenomCorrespondant() {
    return this.prenomCorrespondant;
  }
  getEmailCorrespondant() {
    return this.mailCorrespondant;
  }
  getNumTelCorrespondant() {
    return this.numTelCorrespondant;
  }
  getMentionsLegales() {
    return this.mentionsLegales;
  }
  getQrCode() {
    return this.qrCode;
  }
  getIban() {
    return this.iban;
  }

  // une autre collection liste Scannettes

  // Setters
  setIdOrganisateur(id) {
    return (this.idOrganisateur = id);
  }
  setNom(nom) {
    this.nom = nom;
  }
  setPrenom(prenom) {
    this.prenom = prenom;
  }
  setEmail(email) {
    this.email = email;
  }
  setNumTel(numTel) {
    this.numTel = numTel;
  }

  setSociete(societe) {
    this.societe = societe;
  }
  setSiret(siret) {
    this.siret = siret;
  }
  setApe(ape) {
    this.ape = ape;
  }
  setAdresse(adresse) {
    this.adresse = adresse;
  }
  setCodePostal(codePostal) {
    this.codePostal = codePostal;
  }
  setVille(ville) {
    this.ville = ville;
  }
  setNomCorresponsant(nomCorresponsant) {
    this.nomCorresponsant = nomCorresponsant;
  }
  setPrenomCorrespondant(prenomCorrespondant) {
    this.prenomCorrespondant = prenomCorrespondant;
  }
  setEmailCorrespondant(mailCorrespondant) {
    this.mailCorrespondant = mailCorrespondant;
  }
  setNumTelCorrespondant(numTelCorrespondant) {
    this.numTelCorrespondant = numTelCorrespondant;
  }
  setMentionsLegales(mentionsLegales) {
    this.mentionsLegales = mentionsLegales;
  }
  setQrCode(qrCode) {
    this.qrCode = qrCode;
  }
  setIban(iban) {
    this.iban = iban;
  }

  toString() {
    return `Organisateur: 
  [ idOrganisateur: ${this.getIdOrganisateur()}, 
    nom: ${this.getNom()}, 
    prenom: ${this.getPrenom()}, 
    email: ${this.getEmail()}, 
    numTel: ${this.getNumTel()}, 
    societe: ${this.getSociete()}, 
    siret: ${this.getSiret()}, 
    ape: ${this.getApe()}, 
    adresse: ${this.getAdresse()}, 
    codePostal: ${this.getCodePostal()}, 
    ville: ${this.getVille()}, 
    nomCorresponsant: ${this.getNomCorresponsant()}, 
    prenomCorrespondant: ${this.getPrenomCorrespondant()}, 
    mailCorrespondant: ${this.getEmailCorrespondant()}, 
    numTelCorrespondant: ${this.getNumTelCorrespondant()}, 
    mentionsLegales: ${this.getMentionsLegales()}, 
    iban: ${this.getIban()} ]`;
  }
}

export default Organisateur;

// const orga = new Organisateur("idorga")
// console.log(orga)
