<template>
  <section @click="handleClick()">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "TopBar",
  emits: ["click"],
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #0c5071;
  padding-inline: 3rem;
  padding-block: 1.5rem;
  cursor: pointer;
}
</style>
