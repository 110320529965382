<template>
  <router-view name="Navbar"></router-view>
  <router-view name="NavbarOnboarding"></router-view>

  <MainLayout />
  <!-- See documentation at /src/services/errorService/README.md -->
  <notifications
    position="top right"
    width="350px"
    classes="customNotificationStyle"
  />
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";

export default {
  components: {
    MainLayout,
  },
  data() {
    return {
      filename: "App.vue",
      colors: {
        white: "FFFFFF",
        darkblue: "004766",
        blue: "005C82",
        gold: "#FFD09B",
      },
      fontStyle: {
        general: "Assistant",
        titleSize: "40px",
        normalSize: "28px",
        minSize: "24px",
      },
    };
  },
};
</script>

<style>
html {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  /* height: 100vh; */
  height: 100%;
  background: #004766;
}

#app {
  font-family: "Assistant", sans-serif;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #ffffff; */
  width: 100%;
  color: #ffff;
}

#app a {
  text-decoration: none;
}

.customNotificationStyle {
  margin-inline: 1rem;
  margin-block: 0.5rem;
  padding-block: 0.75rem;
  padding-inline: 1rem;
  border-radius: 0.5rem;
  font-weight: 700;
  border: 2px solid;
  border-left-width: 4px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  .notification-title {
    font-weight: 700;
    font-size: 1rem;
  }

  .notification-content {
    font-size: 0.75rem;
    font-weight: 500;
  }

  &.success {
    background-color: #f1fbf7;
    color: #226855;
    border-color: #48cb99;
  }
  &.info {
    background-color: #f6f4ff;
    color: #6c66fd;
    border-color: #6c66fd;
  }
  &.error {
    background-color: #fdf2f2;
    color: #9f1d1b;
    border-color: #e74e4e;
  }
  &.warn {
    background-color: #fefdeb;
    color: #8e4b0e;
    border-color: #e4a20f;
  }
}
</style>
