<template>
  <div>
    <button class="buttonOr">
      <a class="arrow" href="https://www.nf-ticket.com/" target="_blank"
        >Découvrir NF-Ticket ›</a
      >
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonOne",
};
</script>

<style scoped>
.buttonOr {
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0px 15px;
  background-color: #ffd09b;
  border-radius: 7px;
  color: #005c82;
  border: none;
  position: relative;
  cursor: pointer;
  transition-duration: 0.2s;
  font-size: 15px;
}

.arrow {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonOr:hover {
  background-color: #ffd09b;
  transition-duration: 0.2s;
}

.buttonOr:hover .arrow {
  animation: slide-right 0.6s ease-out both;
}
/* arrow animation */
@keyframes slide-right {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.buttonOr:active {
  transform: translate(1px, 1px);
  transition-duration: 0.2s;
}
</style>
