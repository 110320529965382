<template>
  <router-link :to="directTo">
    <div>
      <span :class="[isActive ? 'navElement active' : 'navElement']">{{
        name
      }}</span>
    </div>
  </router-link>
</template>

<script>
import { useRouter, useRoute } from "vue-router";

export default {
  name: "NavElement",
  props: {
    name: {
      type: String,
      default: "Label",
    },
    directTo: {
      type: Object,
    },
  },
  data(props) {
    const router = useRouter();
    const location = useRoute();

    const routes = router.getRoutes();

    let isActive = false;
    for (const route of routes) {
      if (
        route.name === props.directTo.name &&
        location.path.includes(route.path)
      ) {
        isActive = true;
        break;
      }
    }

    return { isActive, routes, location };
  },

  watch: {
    location: {
      handler() {
        const routes = this.routes;
        let isActive = false;
        for (const route of routes) {
          if (
            route.name === this.directTo.name &&
            this.location.path.includes(route.path)
          ) {
            isActive = true;
            break;
          }
        }
        this.isActive = isActive;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.navElement {
  position: relative;
  font-weight: 700;
  color: white;
  cursor: pointer;
  font-size: 1.12rem;
  transition: 0.2s;
}

.active {
  font-weight: 700;
  color: #ffd09b;
}

.navElement:hover {
  color: #ffd09b;
}

.navElement:after {
  background: none repeat scroll 0 0 transparent;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #ffd09b;
  transition: width 0.2s ease 0s, left 0.2s ease 0s;
  width: 0;
}
.navElement:hover:after {
  width: 100%;
  left: 0;
}
</style>
