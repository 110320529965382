<template>
  <nav class="navbarContainer">
    <div class="leftContainer">
      <Logo />
    </div>
    <div>
      <ButtonOne />
    </div>
  </nav>
</template>

<script>
import Logo from "@/components/Logo";
import ButtonOne from "@/components/ButtonOne";

export default {
  name: "NavbarOnboarding",
  components: {
    Logo,
    ButtonOne,
  },
};
</script>

<style scoped>
.navbarContainer {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  font: "Assistant", sans-serif;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  z-index: 10;
  height: 80px;
  padding-inline: 3rem;
}

.leftContainer {
  display: flex;
  align-items: center;
  gap: 3rem;
}
</style>
