<template>
  <div class="spinnerOverlay">
    <div class="center">
      <p>{{ message }}</p>
      <div class="load"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullPageSpinner",
  props: {
    message: {
      type: String,
      default: "Chargement...",
    },
  },
};
</script>

<style scoped>
.spinnerOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(4px);
  background: rgba(0, 71, 102, 0.5);
  padding: 50px;
  align-items: center;
  z-index: 50;
}

.center {
  align-items: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.load {
  border: 4px solid white;
  border-left-color: transparent;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
