<template>
  <div>
    <router-link :to="{ name: 'Publiés' }" class="routeLogo">
      <img
        src="../assets/logonfticketblancbaseline113063-09ag-200h.png"
        alt=""
      />
      <!-- <img src="../assets/logoNfticket.png" alt=""> -->
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style>
.routeLogo > img {
  width: 160px;
  height: auto;
}
</style>
