<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 1.43503L6.085 1.89503L6.075 9.01502C6.075 10.33 7.065 11.18 8.38 11.18C9.11 11.18 9.645 11.045 9.94 10.885V9.19502C9.655 9.31002 8.255 9.72002 8.255 8.41002V5.25002H9.94V3.36002H8.255L8.25 1.43503ZM12.705 4.01503L12.57 3.36002H10.65V11.02H12.865V5.86502C13.39 5.17502 14.275 5.31002 14.56 5.40002V3.36002C14.26 3.25502 13.225 3.06003 12.705 4.01503ZM17.32 0.400024L15.09 0.875025V2.68503L17.32 2.21002V0.400024ZM2.245 5.61502C2.245 5.27002 2.535 5.13502 3 5.13002C3.675 5.13002 4.535 5.33502 5.21 5.70002V3.61002C4.50747 3.33593 3.75908 3.19847 3.005 3.20502C1.205 3.20502 0.00500107 4.14502 0.00500107 5.71502C0.00500107 8.17502 3.38 7.77502 3.38 8.83502C3.38 9.24502 3.025 9.38002 2.53 9.38002C1.795 9.38002 0.845001 9.07502 0.100001 8.67002V10.67C0.925001 11.025 1.76 11.175 2.525 11.175C4.37 11.175 5.64 10.385 5.64 8.78502C5.64 6.14002 2.245 6.61502 2.245 5.61502ZM32 7.28003C32 5.00503 30.9 3.21002 28.79 3.21002C26.68 3.21002 25.395 5.00502 25.395 7.26502C25.395 9.94002 26.91 11.175 29.07 11.175C30.13 11.175 30.925 10.935 31.53 10.6V8.93003C30.925 9.23503 30.23 9.42002 29.35 9.42002C28.485 9.42002 27.725 9.11503 27.625 8.07503H31.97C31.98 7.96003 32 7.49503 32 7.28003ZM27.605 6.44002C27.605 5.44002 28.22 5.02003 28.775 5.02003C29.32 5.02003 29.9 5.44002 29.9 6.44002H27.605ZM21.96 3.21002C21.09 3.21002 20.53 3.62002 20.22 3.90502L20.105 3.35502H18.15V13.595L20.37 13.125L20.375 10.615C20.695 10.85 21.17 11.175 21.945 11.175C23.535 11.175 24.985 10.015 24.985 7.19502C24.99 4.61502 23.52 3.21002 21.96 3.21002ZM21.43 9.33502C20.91 9.33502 20.6 9.14503 20.385 8.91503L20.37 5.61502C20.6 5.36002 20.92 5.17502 21.43 5.17502C22.24 5.17502 22.8 6.08503 22.8 7.24503C22.805 8.44003 22.255 9.33502 21.43 9.33502ZM15.095 11.02H17.325V3.36002H15.095V11.02Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "StripeIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
    fill: {
      type: String,
      default: "white",
    },
  },
};
</script>
