<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 4.5C5.78587 4.5 5.34075 4.68437 5.01256 5.01256C4.68437 5.34075 4.5 5.78587 4.5 6.25V13.75C4.5 14.716 5.284 15.5 6.25 15.5H13.75C14.2141 15.5 14.6592 15.3156 14.9874 14.9874C15.3156 14.6592 15.5 14.2141 15.5 13.75V11.75C15.5 11.5511 15.579 11.3603 15.7197 11.2197C15.8603 11.079 16.0511 11 16.25 11C16.4489 11 16.6397 11.079 16.7803 11.2197C16.921 11.3603 17 11.5511 17 11.75V13.75C17 14.612 16.6576 15.4386 16.0481 16.0481C15.4386 16.6576 14.612 17 13.75 17H6.25C5.38805 17 4.5614 16.6576 3.9519 16.0481C3.34241 15.4386 3 14.612 3 13.75V6.25C3 5.38805 3.34241 4.5614 3.9519 3.9519C4.5614 3.34241 5.38805 3 6.25 3H8.25C8.44891 3 8.63968 3.07902 8.78033 3.21967C8.92098 3.36032 9 3.55109 9 3.75C9 3.94891 8.92098 4.13968 8.78033 4.28033C8.63968 4.42098 8.44891 4.5 8.25 4.5H6.25ZM10.5 3.75C10.5 3.55109 10.579 3.36032 10.7197 3.21967C10.8603 3.07902 11.0511 3 11.25 3H16.25C16.4489 3 16.6397 3.07902 16.7803 3.21967C16.921 3.36032 17 3.55109 17 3.75V8.75C17 8.94891 16.921 9.13968 16.7803 9.28033C16.6397 9.42098 16.4489 9.5 16.25 9.5C16.0511 9.5 15.8603 9.42098 15.7197 9.28033C15.579 9.13968 15.5 8.94891 15.5 8.75V5.56L11.78 9.28C11.7113 9.35369 11.6285 9.41279 11.5365 9.45378C11.4445 9.49477 11.3452 9.51682 11.2445 9.51859C11.1438 9.52037 11.0438 9.50184 10.9504 9.46412C10.857 9.4264 10.7722 9.37026 10.701 9.29904C10.6297 9.22782 10.5736 9.14299 10.5359 9.0496C10.4982 8.95621 10.4796 8.85618 10.4814 8.75548C10.4832 8.65478 10.5052 8.55546 10.5462 8.46346C10.5872 8.37146 10.6463 8.28866 10.72 8.22L14.44 4.5H11.25C11.0511 4.5 10.8603 4.42098 10.7197 4.28033C10.579 4.13968 10.5 3.94891 10.5 3.75Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "OpenIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
    fill: {
      type: String,
      default: "white",
    },
  },
};
</script>
