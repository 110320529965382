import { createRouter, createWebHistory } from "vue-router";
import { organisateurStore } from "@/store/Pinia/Organisateur.js";
import Navbar from "../components/Navbar/Navbar.vue";
import NavbarOnboarding from "../components/Navbar/NavbarOnboarding.vue";
import StripeValidation from "../components/top-bar/stripe-validation/StripeValidation.vue";

// Définition des routes de l'application
const routes = [
  // Route pour la page NotFound
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
  // Routes principales de l'application
  // {
  //   path: "/",
  //   name: "Paul",
  //   component: () => import("../views/LandingPage2.vue"),
  // },
  // {
  //   path: "/pro",
  //   name: "Home",
  //   component: () => import("../views/Home.vue"),
  // },
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/Test.vue"),
  },

  /* Routes liées à l'authentification */
  {
    path: "/login",
    name: "Login",
    components: {
      default: () => import("../views/Login.vue"),
      NavbarOnboarding,
    },
    meta: {
      login: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    components: {
      default: () => import("../views/Login.vue"),
      NavbarOnboarding,
    },
  },
  // Routes liées au tableau de bord
  {
    path: "/events",
    name: "Publiés",
    components: {
      default: () => import("../views/Dashboard/Event/EventPublished.vue"),
      Navbar,
      StripeValidation,
    },
  },
  {
    path: "/events/draft",
    name: "Brouillons",
    components: {
      default: () => import("../views/Dashboard/Event/EventDraft.vue"),
      Navbar,
      StripeValidation,
    },
  },
  {
    path: "/events/passed",
    name: "Passés",
    components: {
      default: () => import("../views/Dashboard/Event/EventPassed.vue"),
      Navbar,
      StripeValidation,
    },
  },
  {
    path: "/finances",
    name: "Resume",
    components: {
      default: () => import("../views/Dashboard/Tresorerie/Resume.vue"),
      Navbar,
      StripeValidation,
    },
  },
  {
    path: "/dashboard/Tresorerie/Parametres/pro",
    name: "Parametres",
    components: {
      default: () => import("../views/Dashboard/Tresorerie/Parametres.vue"),
      Navbar,
      StripeValidation,
    },
  },
  {
    path: "/events/edit/steps/1",
    name: "Event",
    components: {
      default: () => import("../views/Event/InfoGe.vue"),
      Navbar,
      StripeValidation,
    },
  },
  {
    path: "/events/edit/steps/2",
    name: "Info",
    components: {
      default: () => import("../views/Event/DescrpiVisu.vue"),
      Navbar,
      StripeValidation,
    },
  },
  {
    path: "/events/:eventId",
    name: "EventDashboard",
    components: {
      default: () => import("../views/EventDashboard/Dashboard.vue"),
      SideNavigation: () =>
        import("../components/side-navigation/SideNavigation.vue"),
      Navbar,
      StripeValidation,
    },
  },
  {
    path: "/events/:eventId/tickets-configuration",
    name: "EventTicketsConfiguration",
    components: {
      default: () =>
        import("../views/EventDashboard/EventTicketConfiguration.vue"),
      SideNavigation: () =>
        import("../components/side-navigation/SideNavigation.vue"),
      Navbar,
      StripeValidation,
    },
  },
  {
    path: "/events/:eventId/privacy",
    name: "EventPrivacy",
    components: {
      default: () => import("../views/EventDashboard/EventPrivacy.vue"),
      SideNavigation: () =>
        import("../components/side-navigation/SideNavigation.vue"),
      Navbar,
      StripeValidation,
    },
  },
  {
    path: "/events/:eventId/scanners",
    name: "EventScanners",
    components: {
      default: () => import("../views/EventDashboard/EventScanner.vue"),
      SideNavigation: () =>
        import("../components/side-navigation/SideNavigation.vue"),
      Navbar,
      StripeValidation,
    },
  },
  {
    path: "/events/:eventId/orders",
    name: "EventOrders",
    components: {
      default: () => import("../views/EventDashboard/EventOrder.vue"),
      SideNavigation: () =>
        import("../components/side-navigation/SideNavigation.vue"),
      Navbar,
      StripeValidation,
    },
  },
];

// Création de l'instance du routeur Vue Router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Middleware pour gérer la navigation
router.beforeEach((to, from, next) => {
  const orgaStore = organisateurStore();
  // Si l'utilisateur n'est pas connecté il n'a accès à rien d'autre que la page Login
  if (to.name !== "Login" && !orgaStore.idOrganisateur) {
    next({ name: "Login" });
  } else if (to.name == "Login" && orgaStore.idOrganisateur) {
    next({ name: "Publiés" });
  } else {
    next();
  }
});

// Exportation du routeur pour l'utiliser dans l'application
export default router;
