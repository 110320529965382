// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";

// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//                          DEV

// const firebaseConfig = {
//   apiKey: "AIzaSyAgsERFtceaUXOlD5ZpJ9FVyYiHgo0OB74",
//   authDomain: "nf-ticket.firebaseapp.com",
//   databaseURL:
//     "https://nf-ticket-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "nf-ticket",
//   storageBucket: "nf-ticket.appspot.com",
//   messagingSenderId: "209302452302",
//   appId: "1:209302452302:web:1e5393b84ac2f47a676b1f",
//   measurementId: "G-3CTPSNBP03",
// };

//                         PROD

const firebaseConfig = {
  apiKey: "AIzaSyC7ORsUAd7O6u1mvpqToCXZ4DQBfQLDcpc",
  authDomain: "nf-ticket-prod.firebaseapp.com",
  projectId: "nf-ticket-prod",
  storageBucket: "nf-ticket-prod.appspot.com",
  messagingSenderId: "709496890140",
  appId: "1:709496890140:web:ad4dddf5388c0fb8ba10d9",
  measurementId: "G-YDK9QQH793",
  databaseURL:
    "https://nf-ticket-prod-default-rtdb.europe-west1.firebasedatabase.app",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app, "europe-west3");
const db = getFirestore(app);
const storage = getStorage(app);
const realtimeDb = getDatabase(app);
export { auth, db, storage, functions, realtimeDb };
// const analytics = getAnalytics(app);
