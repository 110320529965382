<template>
  <TopBar @click="handleClick()" v-show="!isStripeAccountLinkValidated">
    <div id="contentWrapper">
      <div id="iconWrapper">
        <AlertIcon />
      </div>
      <div id="panelContent">
        <p id="panelTitle">
          Ajoutez vos informations sur Stripe pour pouvoir publier vos
          événements.
        </p>
        <p>
          Notre partenaire de paiement Stripe doit connaître les informations de
          votre organisation pour pouvoir collecter vos revenus.
        </p>
      </div>
    </div>
  </TopBar>
</template>

<script>
import TopBar from "../TopBar.vue";
import AlertIcon from "@/components/icons/AlertIcon.vue";
import { organisateurStore } from "@/store/Pinia/Organisateur";

export default {
  name: "StripeValidation",
  components: {
    TopBar,
    AlertIcon,
  },

  async setup() {
    const organizerStore = organisateurStore();
    const stripeLinkValidation = await organizerStore.stripeLinkValidation(
      window.location.href
    );
    const isStripeAccountLinkValidated =
      stripeLinkValidation?.isStripeAccountLinkValidated ?? false;

    return {
      stripeLinkValidation,
      isStripeAccountLinkValidated,
    };
  },

  methods: {
    handleClick() {
      window.open(this.stripeLinkValidation.url, "_blank");
    },
  },
};
</script>

<style scoped>
#contentWrapper {
  width: 100%;
  display: flex;
  gap: 1.5rem;
}

#iconWrapper {
  align-items: center;
  flex-wrap: wrap;
  display: flex;
}

#panelTitle {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  color: #dfbf94;
}

#panelContent {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

p {
  font-size: 0.875rem;
  font-weight: 400;
}
</style>
