import { useNotification } from "@kyvg/vue3-notification";

export const ErrorSeverity = {
  ERROR: "error",
  WARNING: "warn",
  SUCCESS: "success",
  INFO: "info",
};

let instance = null;

export const ErrorService = () => {
  /* Singleton initialization */
  if (!instance) {
    const { notify } = useNotification();
    /* Private functions */

    /* Public functions */
    instance = {
      /**
       * @param {ErrorSeverity} severity
       * @param {string} title
       * @param {string} message
       * @returns {void}
       * @description
       * This function handles the error and displays it to the user.
       * It uses the vue3-notification library.
       * The severity parameter must be one of the ErrorSeverity values.
       * The title parameter is the title of the notification.
       * The message parameter is the message of the notification, if the message is not provided, only the title will be displayed.
       *
       * @example
       * import { ErrorService, ErrorSeverity } from "@/services/errorHandling";
       *
       * const customError = ErrorService();
       * customError.handle(ErrorSeverity.ERROR, "Error", "An error occurred");
       */
      handle(severity, title, message) {
        if (!Object.values(ErrorSeverity).includes(severity)) {
          console.error("customError.handle: Invalid severity: ", severity);
        }
        if (severity === ErrorSeverity.ERROR) {
          console.error(`${title}: ${message || ""}`);
        }
        notify({
          title: title,
          text: message,
          type: severity,
        });
      },
    };
  }
  return instance;
};
