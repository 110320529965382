import { auth } from "@/firebaseConfig";
import { db } from "@/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

const organisateurs = "ListeOrganisateurs";

import {
  signInWithEmailAndPassword,
  browserSessionPersistence,
  signOut,
} from "firebase/auth";
import { functions } from "@/firebaseConfig";
import { httpsCallable } from "firebase/functions";
import {
  ErrorService,
  ErrorSeverity,
} from "@/services/errorService/errorService";

const createOrganizer = httpsCallable(functions, "createOrganizerAccount");

const createStripeAccountLinkOrganizer = httpsCallable(
  functions,
  "createStripeAccountLinkOrganizer"
);

const isOrganizerStripeAccountLinkVerified = httpsCallable(
  functions,

  "isOrganizerStripeAccountLinkVerified"
);

const getOrganizerStripeBalance = httpsCallable(
  functions,
  "getOrganizerStripeBalance"
);

const getStripeDashboardLink = httpsCallable(
  functions,
  "getStripeDashboardLink"
);

import { defineStore } from "pinia";
import router from "@/router";

import Organisateur from "../model/organisateur";

const errorService = ErrorService();

export const organisateurStore = defineStore("Organisateur", {
  state: () => {
    return {
      currentUser: null,
      organisateurData: null,
      idOrganisateur: null,
      admin: null,
      isStripeAccountLinkValidated: false,
    };
  },

  persist: {
    storage: sessionStorage, // Data in sessionStorage is cleared when the page session ends.
  },

  actions: {
    adminLog(details) {
      const { password } = details;
      if (password == "1234") {
        this.$state.admin = "Admin";
        router.push({ name: "Publiés" });
      }
    },

    setPseudo(pseudo) {
      this.currentUser.pseudo = pseudo;
    },

    getPseudo() {
      if (this.currentUser) {
        return this.currentUser.pseudo;
      }
      return false;
    },

    getIsStripeAccountLinkValidated() {
      return this.isStripeAccountLinkValidated;
    },

    async newAccount(signUpForm) {
      await auth.signOut(); // Déconnexion de l'utilisateur actuellement connecté.
      (this.currentUser = null),
        (this.organisateurData = null),
        (this.idOrganisateur = null); //Réinitialisation des données utilisateur.

      try {
        const { pseudo, email, password } = signUpForm; // Extraction de l'e-mail, du pseudo et du mot de passe de `signInForm` grâce à la destructuration.

        // Création de l'organisateur
        const result = await createOrganizer(signUpForm);
        this.idOrganisateur = result.data; // Récupération de l'id utilisateur
        const organisateur = new Organisateur(pseudo, email); //Création de l'objet organisateur
        this.currentUser = organisateur; // Mise à jour de l'utilisateur courant

        await auth.setPersistence(browserSessionPersistence); // Configuration de la persistance de session

        // Connexion de l'utilisateur avec l'e-mail et le mot de passe fournis
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        this.idOrganisateur = userCredential.user.uid;
        router.push({ name: "Publiés" });
      } catch (error) {
        errorService.handle(
          ErrorSeverity.ERROR,
          "Erreur lors de la création du compte",
          error.message
        );
        console.log(`${error.code}: ${error.message}}\n${error.details}`);
      }
    },

    async connect(signInForm) {
      await auth.signOut(); // Déconnexion de l'utilisateur actuellement connecté.
      (this.currentUser = null),
        (this.organisateurData = null),
        (this.idOrganisateur = null); //Réinitialisation des données utilisateur.

      try {
        const { email, password } = signInForm; // Extraction de l'e-mail et du mot de passe de `signInForm` grâce à la destructuration.
        await auth.setPersistence(browserSessionPersistence); // Configuration de la persistance de la session dans le navigateur.

        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        ); // Connexion de l'utilisateur avec l'e-mail et le mot de passe fournis.
        const id = userCredential.user.uid; // Récupération de l'ID de l'utilisateur connecté.
        this.idOrganisateur = id; // Stockage de l'ID de l'organisateur dans la propriété `idOrganisateur`.
        const dataQuery = doc(db, organisateurs, id); // Récupération des données de l'organisateur depuis la base de données.
        const userData = (await getDoc(dataQuery)).data(); // Mise à jour des données utilisateurs

        const userSystemDataQuery = doc(
          db,
          organisateurs,
          id,
          "Informations",
          "Systeme"
        );
        const userSystemData = (await getDoc(userSystemDataQuery)).data();

        this.currentUser = {
          ...new Organisateur(),
          ...userData,
          isStripeAccountLinkValidated:
            userSystemData?.isStripeAccountLinkValidated || false,
        }; // Création d'un objet `currentUser` contenant les données de l'organisateur.

        // Redirection de l'utilisateur vers la page 'Publiés'.
        router.push({ name: "Publiés" });
        window.location.reload;
      } catch (error) {
        errorService.handle(
          ErrorSeverity.ERROR,
          "Erreur lors de la connexion",
          error.message
        );
        console.log(`${error.code}: ${error.message}}\n${error.details}`);
      }
    },

    async logout() {
      router.push({ name: "Login" });
      // signOut(auth)
      // this.organisateur = null
      // this.currentUser = null
      // this.$reset()

      // Utilisation de Promise.all pour attendre que toutes les opérations asynchrones se terminent
      await Promise.all([
        signOut(auth), // Déconnexion de l'utilisateur
        this.$reset(), // Réinitialisation des données du formulaire
      ]);

      // Réinitialisation des propriétés
      this.organisateur = null;
      this.currentUser = null;

      // Redirection de l'utilisateur vers la page de connexion
      router.push({ name: "Login" });
    },

    async stripeLinkValidation(redirectUrl) {
      try {
        // Check if linkValidated is true
        if (this.currentUser.isStripeAccountLinkValidated) {
          this.isStripeAccountLinkValidated = true;
          return { url: null, isStripeAccountLinkValidated: true };
        }

        // Verify if the stripe account has been validated by the user
        const isAccountValidated = await isOrganizerStripeAccountLinkVerified();
        if (isAccountValidated.data) {
          this.isStripeAccountLinkValidated = true;
          this.currentUser.isStripeAccountLinkValidated = true;
          return { url: null, isStripeAccountLinkValidated: true };
        }

        // Create a new stripe account link
        const createNewAccount = await createStripeAccountLinkOrganizer({
          redirectUrl,
        });
        const url = createNewAccount.data.url;
        this.isStripeAccountLinkValidated = false;
        return { url, isStripeAccountLinkValidated: false };
      } catch (error) {
        errorService.handle(
          ErrorSeverity.ERROR,
          "Erreur lors de la validation du lien Stripe",
          error.message
        );
        console.log(`${error.code}: ${error.message}}\n${error.details}`);
      }
    },

    async getStripeBalance() {
      try {
        const balance = await getOrganizerStripeBalance();
        return balance.data;
      } catch (error) {
        errorService.handle(
          ErrorSeverity.ERROR,
          "Erreur lors de la récupération du solde Stripe",
          error.message
        );
      }
    },

    async getStripeDashboardLink() {
      try {
        const result = await getStripeDashboardLink();
        return result.data.loginLink;
      } catch (error) {
        errorService.handle(
          ErrorSeverity.ERROR,
          "Erreur lors de la récupération du lien Stripe",
          error.message
        );
      }
    },
  },
});
