<template>
  <Suspense>
    <template #default>
      <router-view name="StripeValidation"></router-view>
    </template>
  </Suspense>
  <div class="main-layout">
    <router-view name="SideNavigation"></router-view>
    <div class="content">
      <Suspense>
        <template #default>
          <router-view />
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainLayout",
};
</script>

<style scoped>
.main-layout {
  display: flex;
  height: 100%;
  width: 100%;
}

.content {
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
