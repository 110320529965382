<template>
  <nav class="navbarContainer">
    <div class="leftContainer">
      <Logo />
      <div class="navElements">
        <NavElement name="Mes événements" :directTo="{ name: 'Publiés' }" />
        <NavElement name="Trésorerie" :directTo="{ name: 'Resume' }" />
      </div>
    </div>
    <div class="right-container">
      <button @click="handleStripeClick">
        <StripeIcon :width="42" fill="#635bff" />
        <OpenIcon :width="20" fill="#635bff" />
      </button>
      <div>
        <NavProfile />
      </div>
    </div>
  </nav>
  <FullPageSpinner v-if="isLoading" message="Veuillez patienter..." />
</template>

<script>
import Logo from "@/components/Logo";
import NavProfile from "@/components/Navbar/components/NavProfile.vue";
import NavElement from "@/components/Navbar/components/NavElement.vue";
import StripeIcon from "@/components/icons/StripeIcon.vue";
import OpenIcon from "@/components/icons/OpenIcon.vue";
import { organisateurStore } from "@/store/Pinia/Organisateur";
import FullPageSpinner from "@/components/utils/FullPageSpinner.vue";

export default {
  name: "Navbar",
  components: {
    Logo,
    NavElement,
    NavProfile,
    StripeIcon,
    OpenIcon,
    FullPageSpinner,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async handleStripeClick() {
      this.isLoading = true;
      const store = organisateurStore();
      const stripeLink = await store.getStripeDashboardLink();
      window.open(stripeLink, "_blank");
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.navbarContainer {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  font: "Assistant", sans-serif;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  z-index: 10;
  height: 80px;
  padding-inline: 3rem;
}

.leftContainer {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.right-container {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.navElements {
  display: flex;
  align-items: center;
  gap: 2rem;
}

button {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  background-color: white;
  border: none;
  border-radius: 5px;
  padding: 0.375rem 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #635bff;
}

button:hover {
  background-color: #f5f5f5;
}
</style>
