<template>
  <div
    :class="`profileContainer ${isActive ? 'active' : ''}`"
    @click="clicked()"
  >
    <img
      class="profilePicture"
      src="../../../assets/default_profile_picture.jpeg"
    />
    <span>{{ pseudo }}</span>
  </div>
  <div v-show="isActive" class="profileMenuContainer">
    <a class="button" href="https://www.nf-ticket.com/" target="_blank">
      <span>Découvrir NF-Ticket</span>
    </a>
    <a class="button" @click="logout()">
      <span>Déconnexion</span>
    </a>
  </div>
</template>

<script>
import { organisateurStore } from "@/store/Pinia/Organisateur";

export default {
  name: "NavProfile",
  setup() {
    const store = organisateurStore();
    let pseudo = store.getPseudo();
    if (pseudo.length >= 12) {
      pseudo = pseudo.slice(0, 12) + "...";
    }

    return {
      store,
      pseudo,
    };
  },

  data() {
    return {
      isActive: false,
    };
  },

  methods: {
    clicked() {
      this.isActive = !this.isActive;
    },

    async logout() {
      await this.store.logout();
      this.$router.push({ name: "Login" });
    },

    navigateToDiscover() {},
  },
};
</script>

<style scoped>
.profileContainer {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 1rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 0.375rem;
  transition: 0.2s;
  cursor: pointer;
  z-index: 99;
  width: 200px;
}

.profilePicture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profileContainer:hover {
  background-color: #004f71;
  box-shadow: 0px 0px 0px 2px #004f71;
}

.active {
  background-color: #004f71;
  box-shadow: 0px 0px 0px 2px #004f71;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.profileMenuContainer {
  display: flex;
  flex-direction: column;
  margin-left: -1.75px;
  position: absolute;
  background-color: #004f71;
  display: flex;
  width: 204px;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.button {
  text-align: right;
  width: 100%;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;
  color: white;
  border-bottom: 1px solid #003c57;
}

.button:first-child {
  border-top: 1px solid #003c57;
}

.button:last-child {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-bottom: none;
}

.button:hover {
  background-color: #003c57;
}
</style>
